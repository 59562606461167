import React from 'react'
import "./topbar.css"

import logo from '../../AZ-DPD-logo.png';

export default function Topbar() {
    return (
        <div className="topbar">
            <div className="topbarWrapper">
                <div className="topLeft">
                    <img className="ca-logo" src={logo} alt=""/>
                    <span className="logo">amazon-dpd Shipping Builder</span>
                </div>
            </div>
        </div>
    )
}
