import "./sidebar.css"
import { NavLink } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { truncateSelectedShipment } from '../../redux/actions/selectedShipment.action';
import { setAllSelectedToFalse } from '../../redux/actions/addresses.action';
import { writeShipmentFile } from '../../redux/actions/shipmentFile.action';

/** ICONS **/
import PostAddIcon from '@mui/icons-material/PostAdd';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DescriptionIcon from '@mui/icons-material/Description';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import EventNoteIcon from '@mui/icons-material/EventNote';

export default function Sidebar({purchaseOrderId, dateCreated}) {
    const day = dateCreated.substring(8,10);
    const month = dateCreated.substring(5,7);
    const year = dateCreated.substring(0,4);
    const fileName = `${purchaseOrderId}-${day}${month}${year}`;
    
    const dispatch = useDispatch();
    return (
        <div className="sidebar">
            <div className="sidebarWrapper">
                {/* <div className="sidebarHeader1"><span>Amazon - dpd</span></div> */}
                {/* <div className="sidebarHeader2"><BeenhereIcon className="shippingIcon" /> <span>SHIPPING BUILDER</span></div> */}
                <div className="sidebarMenu">
                    <h3 className="sidebarTitle">Create Shipping</h3>
                    <ul className="sidebarList">
                        <NavLink to="/fulfilment-address/" activeClassName="selected" onClick={() => {
                            dispatch(setAllSelectedToFalse(1));
                        }}>
                            <span className="sidebarIcon"><LocalShippingIcon /></span>
                            <span className="side-title">Fulfilment Centre Address</span>
                        </NavLink>
                        <NavLink to="/select-shipment/" activeClassName="selected" onClick={() => {dispatch(truncateSelectedShipment())}}>
                            <span className="sidebarIcon"><AppRegistrationIcon /></span>
                            <span className="side-title">Shipment</span>
                        </NavLink>
                        <NavLink to="/delivery-notes/" activeClassName="selected">
                            <span className="sidebarIcon"><EventNoteIcon /></span>
                            <span className="side-title">Delivery notes</span>
                        </NavLink>
                        <NavLink to="/invoice-address/" activeClassName="selected" onClick={() => {
                            dispatch(setAllSelectedToFalse(2));
                        }}>
                            <span className="sidebarIcon"><DescriptionIcon /></span>
                            <span className="side-title">Invoices Address</span>
                        </NavLink>
                        <NavLink to="/review-shipping/" activeClassName="selected" onClick={() => {
                            dispatch(writeShipmentFile(fileName));
                        }}>
                            <span className="sidebarIcon"><LibraryAddCheckIcon /></span>
                            <span className="side-title">Review shipping</span>
                        </NavLink>
                    </ul>
                </div>
                <div className="sidebarMenu">
                    <h3 className="sidebarTitle">Admin</h3>
                    <ul className="sidebarList">
                        <NavLink to="/edit-shipper-address/" activeClassName="selected">
                            <span className="sidebarIcon"><LocationOnIcon /></span>
                            <span className="side-title">Edit Shipper Address</span>
                        </NavLink>
                        <NavLink to="/new-fulfilment-address/" activeClassName="selected" >
                            <span className="sidebarIcon"><LocalShippingIcon /></span>
                            <span className="side-title">Add New Fulfilment Centre Address</span>
                        </NavLink>
                        <NavLink to="/new-invoice-address/" activeClassName="selected" >
                            <span className="sidebarIcon"><PostAddIcon /></span>
                            <span className="side-title">Add New Invoice Address</span>
                        </NavLink>
                    </ul>
                </div>
            </div>
        </div>
    )
}
