import Button from '@material-ui/core/Button';
import './firstPage.css'
import logo from '../../../AZ-DPD-logo.png';
const FirstPage = () => {
    return (
        <div className="firstPage">
            <div className='firstPageWrapper'>
                <div className="firsPageTitle">
                <img className="firsPageAZ-logo" src={logo}  alt="amazon/dpd logo"/>
                    <h1>amazon-dpd Shipping Builder</h1>
                    <a href="/fulfilment-address"><Button className="firsPageStartButton">START</Button></a>
                </div>
            </div>
        </div>
    )
}

export default FirstPage
