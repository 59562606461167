import './reviewShipping.css';
import ReviewTop from "./reviewTop/ReviewTop";
import ReviewShipment from "./reviewShipment/ReviewShipment";
import { Button } from '@mui/material';
import { useDispatch} from 'react-redux';

// import { downloadShipmentFile } from '../../../redux/actions/shipmentFile.action';

// const serverBaseUrl = "http://localhost:5000"
// const serverBaseUrl = "https://zf0q6laveftxq4p7eihy.creativeapparel.co.uk/"
const serverBaseUrl = "https://server-amazon-dpd.emadsaeed.com/"

function ReviewShipping({purchaseOrderId, dateCreated, deliveryNoteSubmitted}) {
    const dispatch = useDispatch();

    const day = dateCreated.substring(8,10);
    const month = dateCreated.substring(5,7);
    const year = dateCreated.substring(0,4);
    const fileName = `${purchaseOrderId}-${day}${month}${year}`;
        console.log("purchaseOrderId", purchaseOrderId);

    return (
        <div className="reviewShipping">
           <ReviewTop  deliveryNoteSubmitted={deliveryNoteSubmitted}/>
           <ReviewShipment purchaseOrderId={purchaseOrderId} dateCreated={dateCreated}/>
           <div className="reviewShippingfooter">
            <a style={{textDecoration: "none"}} href={`${serverBaseUrl}/downloadShipmentFile?fileName=${fileName}`} download>
                <Button variant="contained" color="primary" size="small" className="reviewShippingSubmitButton">To DPD-Import</Button>
            </a>
           </div>
       </div>
    )
}

export default ReviewShipping
